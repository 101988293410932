<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              {{ labels.bsnDate }}
            </div>
            <ul class="content">
              <li class="item date">
                <input-date
                    ref="searchConditionsDatePicker"
                    format="YYYY-MM-DD"
                    v-model="now"
                    type="lookup-condition"
                    :notClear="true"
                    :isGetDateInfo="true"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.caddieName }}
            </div>
            <ul class="content">
              <li class="item input">
                <input-text
                    v-model.trim="searchConditions.caddieName"
                    @focus="searchValueClear"
                    @keydown.native="searchValueKeyDown"
                />
              </li>
            </ul>
            <ul class="content">
              <li class="item button">
                <ul class="button">
                  <li class="search">
                    <erp-button
                        buttonDiv="GET"
                        :isIconCustom="true"
                        :ignore="isPopupOpened"
                        @click.native="onSearchCaddieInfo"
                    >
                      검색
                    </erp-button>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              buttonDiv="GET"
              :isShortcutButton="true"
              :ignore="isPopupOpened"
              @click.native="onViewPaddieRoundTotalTable"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              buttonDiv="GET"
              :isIconCustom="true"
              :ignore="isPopupOpened"
              @click.native="searchDetailPopupOpen"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">캐디 라운드 집계표</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      buttonDiv="FILE"
                      :ignore="isPopupOpened"
                      @click.native="excel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid caddieRoundTotalTableGrid">
              <ejs-grid-wrapper
                  ref="caddieRoundTotalTableGrid"
                  :allowExcelExport="true"
                  :allowPaging="true"
                  :allowGrouping="true"
                  :allowResizing="true"
                  :provides="grid"
                  :pageSettings="pageSettings"
                  :groupSettings="groupSettings"
                  :columns="caddieRoundTotalTableGridColumn"
                  :dataSource="caddieRoundTotalTableList"
                  :aggregates="caddieRoundTotalTableGridAggregates"
                  @headerCellInfo="caddieRoundTotalTableGridHeaderCellInfo"
                  @queryCellInfo="caddieRoundTotalTableGridQueryCellInfo"
                  @actionComplete="caddieRoundTotalTableGridActionComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <ejs-dialog
        ref="searchDetailPopup"
        :header="`상세검색`"
        :allowDragging="true"
        :showCloseIcon="true"
        width="387"
        :animationSettings="{ effect: 'None' }"
        :isModal="false"
        :visible="false"
        enableResize="true"
        v-show="isSearchDetailPopupOpen"
    >
      <div class="window lookupDetail-caddieInfoRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li
                    class="field"
                    v-for="(item,index) in searchDetailList" :key="index"
                >
                  <div class="title">{{ labels[item] }}</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                          :ref="`${item}MultiSelect`"
                          cssClass="lookupDetail-condition-multiselect"
                          placeHolder="전체"
                          v-model="searchConditions[item]"
                          :dataSource="searchOptions[item]"
                          :fields="commonCodeFields"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  buttonDiv="GET"
                  :isShortcutButton="true"
                  @click.native="onViewPaddieRoundTotalTable"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  buttonDiv="GET"
                  @click.native="searchDetailInit"
                  :isIconCustom="true"
              >
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="searchDetailPopupClose"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <caddie-select-popup
        v-if="isCaddieSelectPopupOpen"
        ref="caddieSelectPopup"
        @popupClosed="closeCaddieSelectPopup"
        @popupConfirmed="caddieSelectPopupConfirmed"
    />
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import moment from 'moment';
import {mapGetters} from 'vuex';
import {DATE_FORMAT_YYYY_MM_DD, getDayOfWeekCaptionColor} from '@/utils/date';
import {numberWithCommas} from '@/utils/number';
import {Aggregate, ExcelExport, Filter, ForeignKey, Page, Group, Resize} from '@syncfusion/ej2-vue-grids';
import ejsGridWrapper from '@/components/common/EjsGridWrapper.vue';
import InputText from '@/components/common/text/InputText';
import InputDate from '@/components/common/datetime/InputDate';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import {commonCodesGetColorValue, commonCodesGetCommonCode, commonCodesGetComName} from '@/utils/commonCodes';
import CaddieSelectPopup from '../popup/CaddieSelectPopup';
import GolfERPService from '@/service/GolfERPService';

export default {
  name: 'CaddieRoundTotalTable',
  components: {
    ejsGridWrapper,
    InputText,
    InputDate,
    CaddieSelectPopup,
    ErpButton
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data() {
    return {
      count: 0,
      labels: {
        bsnDate: '기준일자',
        caddieName: '캐디명',
        caddieEmployDiv: '재직구분',
        caddieGroupDiv: '조구분',
        caddieWorkDiv: '근무구분',
        caddieGrade: '캐디등급',
      },
      searchConditions: {
        caddieName: null,
        caddieId: null,
        caddieEmployDiv: ['EMPLOY'],
        caddieGroupDiv: null,
        caddieWorkDiv: null,
        caddieGrade: null,
      },
      searchOptions: {
        caddieEmployDiv: commonCodesGetCommonCode('CADDIE_EMPLOY_DIV', true),
        caddieGroupDiv: commonCodesGetCommonCode('CADDIE_GROUP_DIV', true),
        caddieWorkDiv: commonCodesGetCommonCode('CADDIE_WORK_DIV', true),
        caddieGrade: commonCodesGetCommonCode('CADDIE_GRADE', true),
      },
      searchDetailList: [
        'caddieEmployDiv',
        'caddieGroupDiv',
        'caddieWorkDiv',
        'caddieGrade',
      ],
      commonCodeFields: {text: 'comName', value: 'comCode'},
      now: null,
      yydwDate: null,
      grid: [
        Aggregate,
        Filter,
        Resize,
        Page,
        Group,
        ExcelExport,
        ForeignKey,
      ],
      caddieRoundTotalTableGridColumn: [
        {
          field: '_rid',
          type: 'number',
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: 'caddieName',
          headerText: '캐디명',
          width: 90,
          type: 'string',
        },
        {
          field: 'caddieGroupDiv',
          headerText: '조번호',
          textAlign: 'center',
          width: 70,
          type: 'string',
        },
        {
          field: 'caddieNo',
          headerText: '캐디번호',
          textAlign: 'center',
          width: 70,
          type: 'number',
        },
        {
          field: 'sexCode',
          headerText: '성별',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'SEX_CODE',
          width: 70,
          type: 'string',
        },
        {
          field: 'caddieWorkDiv',
          headerText: '근무구분',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'CADDIE_WORK_DIV',
          width: 90,
          type: 'string',
        },
        {
          field: 'caddieEmployDiv',
          headerText: '재직구분',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'CADDIE_EMPLOY_DIV',
          width: 90,
          type: 'string',
        },
        {
          field: 'entryDate',
          headerText: '입사일자',
          textAlign: 'center',
          width: 90,
          type: 'string',
        },
        {
          field: 'workingYears',
          headerText: '근속년수',
          textAlign: 'right',
          width: 90,
          type: 'string',
        },
        {
          headerText: '주간',
          columns: [
            {
              field: 'yydwWorkCnt',
              headerText: '근무횟수',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
            {
              field: 'yydwCaddieFee',
              headerText: '캐디피',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
          ],
        },
        {
          headerText: '월간',
          columns: [
            {
              field: 'yymmWorkCnt',
              headerText: '근무횟수',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
            {
              field: 'yymmCaddieFee',
              headerText: '캐디피',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
          ],
        },
        {
          headerText: '년간',
          columns: [
            {
              field: 'yyyyWorkCnt',
              headerText: '근무횟수',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
            {
              field: 'yyyyCaddieFee',
              headerText: '캐디피',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
          ],
        },
        {
          headerText: '평균',
          columns: [
            {
              field: 'avgWorkCnt',
              headerText: '근무횟수',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
            {
              field: 'avgCaddieFee',
              headerText: '캐디피',
              textAlign: 'right',
              isNumericType: true,
              width: 90,
              type: 'number',
            },
          ],
        },
      ],
      caddieRoundTotalTableGridAggregates: [
        {
          columns: [
            {
              field: 'workingYears',
              aggregationType: 'TotalCaption',
              customAggregate: '합계',
            },
            {
              field: 'yydwWorkCnt',
              aggregationType: 'TotalSum',
            },
            {
              field: 'yydwCaddieFee',
              aggregationType: 'TotalSum',
            },
            {
              field: 'yymmWorkCnt',
              aggregationType: 'TotalSum',
            },
            {
              field: 'yymmCaddieFee',
              aggregationType: 'TotalSum',
            },
            {
              field: 'yyyyWorkCnt',
              aggregationType: 'TotalSum',
            },
            {
              field: 'yyyyCaddieFee',
              aggregationType: 'TotalSum',
            },
          ],
        },
      ],
      caddieRoundTotalTableList: [],
      tempHeaderCellInfoList: [],
      pageSettings: {pageSize: 50},
      groupSettings: {
        columns: [],
        showDropArea: false,
      },
      isCaddieSelectPopupOpen: false,
      isSearchDetailPopupOpen: false,
    };
  },
  watch: {
    $route() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
  },
  async created() {
    await this.initialize();
  },
  computed: {
    ...mapGetters(['username']),
    getStartOf() {
      return moment(this.now)
          .add(-1, 'days')
          .startOf('week')
          .add(1, 'days')
          .format(DATE_FORMAT_YYYY_MM_DD);
    },
    isPopupOpened() {
      return (
          this.isCaddieSelectPopupOpen ||
          this.isSearchDetailPopupOpen
      );
    },
  },
  methods: {
    getDayOfWeekCaptionColor,
    commonCodesGetComName,
    commonCodesGetColorValue,
    numberWithCommas,
    async initialize() {
      const nowMoment = await GolfERPService.fetchNow();
      this.now = moment(nowMoment).format(DATE_FORMAT_YYYY_MM_DD);
      this.yydwDate = this.getStartOf;
      await this.fetch();
    },
    async onViewPaddieRoundTotalTable() {
      await this.fetch();
    },
    searchValueClear() {
      console.log('A');
      this.searchConditions.caddieName = null;
      this.searchConditions.caddieId = null;
    },
    searchValueKeyDown(event) {
      this.searchConditions.caddieId = null; //동명이인 팝업을 거치지 않고 검색할시 기존에 셋팅된 Id를 제거
      if (event.key === 'Enter') {
        this.fetch();
      }
    },
    onSearchCaddieInfo() {
      this.openCaddieSelectPopup(this.searchConditions.caddieName, 'textField');
    },
    searchDetailInit() {
      this.searchDetailList.forEach(item => this.searchConditions[item] = null);
      this.searchConditions.caddieEmployDiv = ['EMPLOY'];
    },
    searchDetailPopupOpen() {
      this.isSearchDetailPopupOpen = true;
      this.$refs.searchDetailPopup.show();
    },
    searchDetailPopupClose() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
    openCaddieSelectPopup(caddieName, field, rowIndex) {
      this.isCaddieSelectPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.caddieSelectPopup.showPopup(caddieName, field, rowIndex);
      });
    },
    closeCaddieSelectPopup() {
      this.isCaddieSelectPopupOpen = false;
    },
    caddieSelectPopupConfirmed(data) {
      this.isCaddieSelectPopupOpen = false;
      if (data.selectedRowData) {
        if (data.field === 'textField') {
          this.searchConditions.caddieName = data.selectedRowData.caddieName;
          this.searchConditions.caddieId = data.selectedRowData.caddieId;
        }
      }
    },
    caddieRoundTotalTableGridHeaderCellInfo(args) {
      const {
        cell: {column: {headerText}},
      } = args;
      if ([
        '주간',
        '월간',
        '년간',
        '평균',
      ].includes(headerText)) {
        this.tempHeaderCellInfoList.push(args);
      }
    },
    caddieRoundTotalTableGridHeaderCellInfoAfter() {
      this.tempHeaderCellInfoList.forEach(item => {
        const {
          cell: {column: {headerText}},
          node,
        } = item;
        switch (headerText) {
          case '주간':
            node.innerHTML = `${headerText}<br>(${this.yydwDate} ~ ${this.now})`;
            break;
          case '월간':
            // eslint-disable-next-line no-case-declarations
            const fromMonth = moment(this.now)
                .startOf('month')
                .format(DATE_FORMAT_YYYY_MM_DD);
            node.innerHTML = `${headerText}<br>(${fromMonth} ~ ${this.now})`;
            break;
          case '년간':
            // eslint-disable-next-line no-case-declarations
            const fromYear = moment(this.now)
                .startOf('year')
                .format(DATE_FORMAT_YYYY_MM_DD);
            node.innerHTML = `${headerText}<br>(${fromYear} ~ ${this.now})`;
            break;
          case '평균':
            node.innerHTML = `${headerText}<br>(년간 횟수 / 년간 근무 월수)`;
            break;
        }
        node.style.textAlign = 'center';
      });
    },
    caddieRoundTotalTableGridQueryCellInfo(args) {
      const {
        cell,
        column: {field},
        data,
      } = args;
      const findIndex = this.caddieRoundTotalTableList
          .findIndex(item => item._rid === data._rid);
      if (findIndex > 0) {
        if (
            this.caddieRoundTotalTableList[findIndex].caddieGroupDiv !==
            this.caddieRoundTotalTableList[findIndex - 1].caddieGroupDiv
        ) {
          cell.classList.add(this.$t('className.grid.devReservationPartDivLine'));
        }
      }
      if ((field?.endsWith('WorkCnt') || field?.endsWith('CaddieFee')) && data[field] < 1) {
        cell.innerText = '-';
      }
    },
    caddieRoundTotalTableGridActionComplete() {
      this.count = numberWithCommas(
          this.$refs.caddieRoundTotalTableGrid?.getGridBatchCount() || 0,
      );
      this.caddieRoundTotalTableGridHeaderCellInfoAfter();
    },
    excel() {
      this.$refs.caddieRoundTotalTableGrid.excelExport();
    },

    // API
    async fetch() {
      this.yydwDate = this.getStartOf;
      this.caddieRoundTotalTableList = (await GolfErpAPI.fetchCaddieRoundTotalTable({
        bsnDate: this.now,
        yydwDate: this.yydwDate,
        caddieName: this.searchConditions.caddieId && this.searchConditions.caddieId !== ''
            ? this.searchConditions.caddieId
            : this.searchConditions.caddieName && this.searchConditions.caddieName !== ''
                ? this.searchConditions.caddieName
                : undefined,
        caddieEmployDiv: this.searchConditions.caddieEmployDiv !== null
            ? this.searchConditions.caddieEmployDiv
            : undefined,
        caddieGroupDiv: this.searchConditions.caddieGroupDiv !== null
            ? this.searchConditions.caddieGroupDiv
            : undefined,
        caddieWorkDiv: this.searchConditions.caddieWorkDiv !== null
            ? this.searchConditions.caddieWorkDiv
            : undefined,
        caddieGrade: this.searchConditions.caddieGrade !== null
            ? this.searchConditions.caddieGrade
            : undefined,
      })).map((item, index) => {
        item._rid = index + 1;
        if (item.caddieGroupDiv) {
          item.caddieGroupDiv = `${item.caddieGroupDiv}조`;
        }
        if (item.avgWorkCnt) {
          item.avgWorkCnt = Math.floor(item.avgWorkCnt);
        }
        if (item.avgCaddieFee) {
          item.avgCaddieFee = Math.floor(item.avgCaddieFee / 100) * 100;
        }
        return item;
      }) || [];
    },
  },
};
</script>
